import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Chat from './components/Chat';
import Demo from './components/Demo';
import './css/App.css';
import './css/Chat.css';
import './css/Stacks.css';

const App: React.FC = () => {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Demo />} />
          <Route path="/beta" element={<Chat />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;