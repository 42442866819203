import React, { useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

function Stack( {ID, Data, sendMessage, mobileOpen} )
{
	let isDemo = false;
	let Stack = Data.Stacks.filter(S => S.id == ID);

	// Demo, get Stack ID from Question
	if(Stack.length == 0)
	{
		isDemo = true;
		let Question = Data.Questions.filter(Q => Q.id === ID)[0];
		Stack = Data.Stacks.filter(S => S.id == Question.fields['STACK LINK MAIN']);
		
		// If there's no stack, don't show anything
		if(Stack.length == 0)
		{
			return (
				<div className="centered">
        	Chat with Healthful's AI to get natural product recommendations from top doctors and nutritionists worldwide.
        </div>
			);
		}
	}

	Stack = Stack[0];

	let Doctor = Data.Doctors.filter(D => D.fields.Name == Stack.fields.Doctor);
			Doctor = (Doctor.length > 0 ? Doctor[0] : false);
	let Products = Data.Products.filter(P => Stack.fields.Products.includes(P.id));

	const handleMessageClick = (Message) => {
		sendMessage(Message);
		mobileOpen();
	}

	return (
		<>
			{Doctor && (
				<div className="drawer icon doctor">

					<h2>
						{Doctor.fields.Name}
						<span className="mobile-close" onClick={mobileOpen}></span>
					</h2>

					<div className="bio">

						<div className="photo">
							{Doctor.fields['Doctor Pic Attachment'] && (<img src={Doctor.fields['Doctor Pic Attachment'][0].url} alt={Doctor.fields.Name} />)}
							<span>{Doctor.fields.Name}: {Doctor.fields['Brief Bio']}</span>
						</div>

						<p><ReactMarkdown>{Doctor.fields['Long Bio']}</ReactMarkdown></p>
						<p>For your issue {Doctor.fields.Name} recommends the <strong>{Stack.fields.Name}</strong>.</p>

						<div className="drawer-actions">
							{!isDemo && (
								<button onClick={() => handleMessageClick(`Tell me more about ${Doctor.fields.Name}`)}>
									Tell me more about {Doctor.fields.Name}
								</button>
							)}
							{Doctor.fields.Website && (<a href={Doctor.fields.Website} target="_blank">Website</a>)}
							{Doctor.fields.Wikipedia && (<a href={Doctor.fields.Wikipedia} target="_blank">Wikipedia</a>)}
							{Doctor.fields.Twitter && (<a href={Doctor.fields.Twitter} target="_blank">Twitter</a>)}
						</div>

					</div>

				</div>)}

			{Stack && (
				<div className="drawer icon stack">

					<h2>
						{Stack.fields.Name}
						<span className="mobile-close" onClick={mobileOpen}></span>
					</h2>

					<p>{Stack.fields.Description}</p>

					<div className="products">
						{Products.map((Product) => (
							<div key={Product.id} className="product">
								{Product.fields['Product Image'] && (
									<img src={Product.fields['Product Image'][0].url} alt={Product.fields.Name} />
								)}
									<span className="name">{Product.fields.Name}</span>
									{Product.fields.Intents && Product.fields.Intents.length > 0 && (
										Product.fields.Intents.map((Intent) => (
											<span className="intent" key={Intent}>
												✔ {Data.Intents.filter(I => I.id == Intent)[0].fields.Name}
											</span>
										))
									)}
							</div>
						))}
					</div>

					<div className="drawer-actions">
						{!isDemo && (
							<>
								<button onClick={() => handleMessageClick(`Who should take the ${Stack.fields.Name}?`)}>
									Who should take this?
								</button>
								<button onClick={() => handleMessageClick(`How to take the ${Stack.fields.Name}?`)}>
									How to take this stack?
								</button>
							</>
						)}
						{Stack.fields.Link && (<a href={Stack.fields.Link} target="_blank">How can I trust this?</a>)}
					</div>

				</div>
			)}
		</>
	);
}

export default Stack;