export const sendMessage = async (sessionId, message, isChoice = false) => {
  const requestBody = {
    session_id: sessionId,
    user_input: isChoice ? message : message,
    reset_conversation: false,
  };

  try {
    const response = await fetch(
      'https://stoa-fastapi-production.up.railway.app/chat/respond_stream',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      }
    );

    return response;
  } catch (error) {
    console.error('Failed to fetch:', error);
    return null;
  }
};