import React, { useState, useRef, useEffect } from 'react';
import Header from '../components/Header';
import DemoSection from '../components/DemoSection';
import Stack from '../components/Stack';

function Demo() {
    const [sections, setSections] = useState([]);
    const [data, setData] = useState({
      Questions: [],
      Stacks: [],
      Doctors: [],
      Products: [],
      Intents: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const scrollableDivRef = useRef(null);
    const [mobileOpen, setMobileOpen] = useState(false);

    const stackRef = useRef(null);

    const handleMobileOpen = () => {
        stackRef.current.scrollTo(0, 0);
        setMobileOpen(prevHistory => !prevHistory);
      }

    const addSection = ID => {
        setSections(prevSections => [...prevSections, ID]);
        stackRef.current.scrollTo(0, 0);
    };
    
    const resetDemo = () => {
        setSections([]);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const dataTypes = ["Questions", "Stacks", "Doctors", "Products", "Intents"];
            try {
                const fetchPromises = dataTypes.map(type =>
                    fetch(`https://api.stayhealthful.com/v0/data?type=${type}`)
                        .then(response => {
                          if (!response.ok) throw new Error(`Failed to fetch ${type}`);
                          return response.json();
                        })
                );
                
                const results = await Promise.all(fetchPromises);
                
                // Map the results back to our data object
                const newData = dataTypes.reduce((acc, dataType, index) => {
                    acc[dataType] = results[index];
                    return acc;
                }, {});

                setData(newData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const scrollDiv = scrollableDivRef.current;
        if (scrollDiv) {
            const observer = new MutationObserver(() => {
                scrollDiv.scrollTo({
                    top: scrollDiv.scrollHeight,
                    behavior: 'smooth'
                });
            });
            observer.observe(scrollDiv, {
                childList: true,
                subtree: true,
                characterData: true,
            });

            return () => observer.disconnect();
        }
    }, []);

    return (
        <>
            <Header />
            <div className="chat">
                <div className="messages demo" ref={scrollableDivRef}>
                    {!isLoading && sections.length === 0 && (
                        <div className="choice" onClick={() => { addSection('recLDyC7C369fNgYn') }}>
                            I want to live long past 100. What can I take to promote longevity?
                        </div>
                    )}
                    {sections.map((ID, index) => (
                        <DemoSection
                          key={ID}
                          ID={ID}
                          addSection={addSection}
                          resetDemo={resetDemo}
                          mobileOpen={handleMobileOpen}
                          isLast={index === sections.length - 1}
                          data={data} // Pass all data types as props
                        />
                    ))}
                </div>
            </div>

            <div className={mobileOpen ? '' : 'mobile-minimized'}>
                <div className="recommendations" ref={stackRef}>
                {sections.length == 0 && (
                    <div className="centered">
                    Chat with Healthful's AI to get natural product recommendations from top doctors and nutritionists worldwide.
                    </div>
                )}
                {sections.length > 0 && (
                    <Stack ID={sections[sections.length - 1]} Data={data} sendMessage={() => { return; }} mobileOpen={handleMobileOpen} />
                )}
                </div>
            </div>

        </>
    );
}

export default Demo;
