import React, { useState } from 'react';
import Typewriter from './Typewriter';
import ReactMarkdown from 'react-markdown';

type OriginType = 'agent' | 'user';

interface MessageProps {
  text: string;
  type?: boolean;
  origin: OriginType;
  onComplete?: () => void;
}

const Message: React.FC<MessageProps> = ({ text, type = true, origin, onComplete }) => (
  <div className={origin}>
    <div className="text">
      {type ? (
        <Typewriter Text={text} Speed={6} onComplete={onComplete} />
      ) : (
        <ReactMarkdown>{text}</ReactMarkdown>
      )}
    </div>
  </div>
);

interface ChoiceProps {
  text: string;
  onClick: (id?: string) => void;
  id?: string;
}

const Choice: React.FC<ChoiceProps> = ({ text, onClick, id }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    if (id) {
      onClick(id);
      setIsVisible(false);
    } else {
      onClick();
    }
  };

  if (!isVisible) return null;

  return (
    <div className="choice" onClick={handleClick}>
      {text}
    </div>
  );
};

export { Message, Choice };