export const readStream = async (
  streamRef,
  setAgentResponse,
  setChoices,
  setShowChoices,
  setStacks,
  handleStreamEnd,
  chatContainerRef
) => {
  const decoder = new TextDecoder();
  let result = '';

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const streamReader = async () => {
    const { value, done } = await streamRef.current.read();
    if (done) {
      handleStreamEnd(result);
      scrollToBottom();
      return;
    }

    result += decoder.decode(value, { stream: true });
    let hasMessage = '';
    let hasChoices = [];
    let hasStacks = [];
    let Chunk = result.split('\n').filter((c) => c);

    Chunk.forEach((c) => {
      try {
        let obj = JSON.parse(c.substring(6));
        if ('chunk' in obj) hasMessage += obj.chunk;
        if ('buttons' in obj) hasChoices = obj.buttons;
        if ('recomm_new' in obj) hasStacks = obj.recomm_new;
      } catch (error) { /* Fail silently. */ }
    });
    //console.log(hasStacks);
    if(hasStacks.length > 0) 
    {
      
      setStacks(hasStacks);
    }

    setAgentResponse(hasMessage);

    if (hasChoices.length > 0) {
      setChoices(hasChoices);
      setShowChoices(true);
      scrollToBottom();
    } else {
      setChoices([]);
      setShowChoices(false);
    }

    scrollToBottom();

    streamReader();
  };

  streamReader();
};