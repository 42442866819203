// Header.js
import React from 'react';

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <a href="https://www.stayhealthful.com/">
			<img src="logo.png" alt="Logo" />
		</a>
        <span>AI Demo</span>
      </div>
    </header>
  );
}

export default Header;
