import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Stack from '../components/Stack';
import { Message, Choice } from '../components/Messages';
import { sendMessage } from '../utils/Api';
import { readStream } from '../utils/readStream';
import { v4 as uuidv4 } from 'uuid';

const Chat = () => {

  const [sessionId, setSessionId] = useState('');

  const [Data, setData] = useState({
    Questions: [],
    Stacks: [],
    Doctors: [],
    Products: [],
    Intents: []
  });

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [choices, setChoices] = useState([]);
  const [agentResponse, setAgentResponse] = useState('');
  const [showChoices, setShowChoices] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stacks, setStacks] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);

  const streamRef = useRef(null);
  const inputRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const stackRef = useRef(null);

  const handleMobileOpen = () => {
    stackRef.current.scrollTo(0, 0);
    setMobileOpen(prevHistory => !prevHistory);
  }

  useEffect(() => {
    if (stackRef.current) {
      stackRef.current.scrollTop = 0;
    }
  }, [stacks]);

  const handleSendMessage = async (messageFromParam = null) => {
    const messageToSend = messageFromParam || message;
  
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { role: 'user', content: messageToSend },
    ]);
    setMessage('');
    setShowChoices(false);
    setIsLoading(true);
  
    const response = await sendMessage(sessionId, messageToSend);
    if (response) {
      streamRef.current = response.body.getReader();
      readStream(
        streamRef,
        setAgentResponse,
        setChoices,
        setShowChoices,
        setStacks,
        handleStreamEnd,
        messagesContainerRef
      );
    }
  
    setIsLoading(false);
  };

  const handleChoiceClick = async (choice) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { role: 'user', content: choice },
    ]);
    setShowChoices(false);
    setIsLoading(true);

    const response = await sendMessage(sessionId, choice, true);
    if (response) {
      streamRef.current = response.body.getReader();
      readStream(
        streamRef,
        setAgentResponse,
        setChoices,
        setShowChoices,
        setStacks,
        handleStreamEnd,
        messagesContainerRef
      );
    }

    setIsLoading(false);
  };

  const handleStreamEnd = (result) => {
    let text = '';
    let msg = result.split('\n').filter((m) => m);

    msg.forEach((m) => {
      try {
        let obj = JSON.parse(m.substring(6));
        if ('chunk' in obj) text += obj.chunk;
      } catch (error) {}
    });

    setChatHistory((prevHistory) => [
      ...prevHistory,
      { role: 'agent', content: text },
    ]);
    setAgentResponse('');

    inputRef.current.focus();
  };

  const handleResetChat = () => {
    setSessionId(uuidv4());
    setChatHistory([]);
    setChoices([]);
    setAgentResponse('');
    setShowChoices(false);
    setStacks([]);
  };

  useEffect(() => {
    // Generate a new session ID when the component mounts
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, []);

  useEffect(() => {
    return () => streamRef.current && streamRef.current.cancel();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        setIsDataLoading(true);
        const dataTypes = ["Questions", "Stacks", "Doctors", "Products","Intents"];
        try {
            const fetchPromises = dataTypes.map(type =>
                fetch(`https://api.stayhealthful.com/v0/data?type=${type}`)
                    .then(response => {
                      if (!response.ok) throw new Error(`Failed to fetch ${type}`);
                      return response.json();
                    })
            );
            
            const results = await Promise.all(fetchPromises);
            
            // Map the results back to our data object
            const newData = dataTypes.reduce((acc, dataType, index) => {
                acc[dataType] = results[index];
                return acc;
            }, {});

            setData(newData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsDataLoading(false);
        }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [choices]);

  let hasStack, hasProducts;

  if(stacks.length > 0)
  {
    hasStack = Data.Stacks.filter(S => S.id == stacks[0].id)[0];
    hasProducts = Data.Products.filter(P => hasStack.fields.Products.includes(P.id));
  }
  

  return (
    <>
      <Header />
      <div className="chat">
        <div className="messages" ref={messagesContainerRef}>
          <div>
            {chatHistory.map((message, index) => (
              <Message key={index} origin={message.role} text={message.content} type={false} />
            ))}
            {agentResponse && (
              <Message origin="agent" text={agentResponse} type={false} />
            )}
            {showChoices && hasStack && (
              <div className="choice mobile-only" onClick={handleMobileOpen}>
                <div className="choice-text">View <strong>{hasStack.fields.Name}</strong></div>
                {hasProducts && hasProducts.length > 0 && (
                  hasProducts.map((Product) => (
                    Product.fields['Product Image'] && (
                      <span className="choice-product" key={Product.id}>
                        <img src={Product.fields['Product Image'][0].url} alt={Product.fields.Name} />
                      </span>
                    )
                  ))
                )}
              </div>
            )}
            {showChoices && (
              <div>
                {choices.map((choice, index) => (
                  <Choice key={index} text={choice} onClick={() => handleChoiceClick(choice)} />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="chat-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}
          >
            {isDataLoading && (<p>Loading data, please wait.</p>)}
            {!isDataLoading && !isLoading && (
              <>
                  <input
                    type="text"
                    value={message}
                    placeholder="Write your question here..."
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={isLoading}
                    ref={inputRef}
                  />
                  <p>... or <a onClick={handleResetChat}>start new chat</a>.</p>
                </>
            )}
          </form>
        </div>
      </div>
      <div className={mobileOpen ? '' : 'mobile-minimized'}>
        <div className="recommendations" ref={stackRef}>
          {stacks.length == 0 && (
            <div className="centered">
            Chat with Healthful's AI to get natural product recommendations from top doctors and nutritionists worldwide.
          </div>
          )}
          {stacks.length > 0 && (
            <Stack ID={stacks[0].id} Data={Data} sendMessage={handleSendMessage} mobileOpen={handleMobileOpen} />
          )}
        </div>
      </div>
    </>
  );
};

export default Chat;
