import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

interface TypewriterProps {
  Text?: string;
  Speed: number;
  onComplete?: () => void;
}

const Typewriter: React.FC<TypewriterProps> = ({ Text = '', Speed, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;

    const typeNextChar = () => {
      setDisplayedText((current) => Text.substring(0, currentIndex));
      currentIndex++;

      if (currentIndex > Text.length) {
        if (onComplete) {
          onComplete();
        }
        return;
      }

      setTimeout(typeNextChar, Speed);
    };

    setTimeout(typeNextChar, Speed);
  }, [Text, Speed, onComplete]);

  return <ReactMarkdown>{displayedText}</ReactMarkdown>;
};

export default Typewriter;