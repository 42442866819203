
import React, { useState } from 'react';
import { Message, Choice } from '../components/Messages';

function DemoSection( { ID, isLast, addSection, resetDemo, mobileOpen, data  }) {
	
	const Question = data.Questions.filter(D => D.id === ID)[0].fields;
	
	const [responseComplete, setResponseComplete] = useState(false);
	const handleResponseComplete = () => { setResponseComplete(true); };
	
	
	function getID(Response) {
		let Result = data.Questions.filter(D => D.fields["User Question"] === Response);
		return (Result.length > 0 ? Result[0].id : false);
	}
	
	let Stack = false;
	let Doctor = false;
	let hasProducts = false;
	
	if(Question['STACK LINK MAIN'])
	{
		Stack = data.Stacks.filter(S => S.id == Question['STACK LINK MAIN'])[0];
		Doctor = data.Doctors.filter(D => D.fields.Name == Stack.fields.Doctor)[0];
		hasProducts = data.Products.filter(P => Stack.fields.Products.includes(P.id));
	}
	
	const handleClick = () => {
		mobileOpen(); // Call the toggle handler passed as prop
  };
	
	return (
	
		<>
			<Message origin="user" text={Question["User Question"]} type={false} />
			<Message origin="agent" text={Question["Desired AI Answer"]} type={!responseComplete} onComplete={handleResponseComplete} />
			
			
			{responseComplete && isLast && Stack && hasProducts && (
        <div className="choice mobile-only" onClick={handleClick}>
          <div className="choice-text">View <strong>{Stack.fields.Name}</strong></div>
          {hasProducts && hasProducts.length > 0 && (
            hasProducts.map((Product) => (
              Product.fields['Product Image'] && (
                <span className="choice-product" key={Product.id}>
                  <img src={Product.fields['Product Image'][0].url} alt={Product.fields.Name} />
                </span>
              )
            ))
          )}
        </div>
      )}


			<>
				{
				  responseComplete && isLast && 
					Object.keys(Question)
					.filter(key => key.startsWith("Response") && Question[key]) // Filters only keys that start with 'Response' and have a truthy value
					.map((responseKey) => {
					  const id = getID(Question[responseKey]);
					  return (
						id !== false && <Choice key={id} text={Question[responseKey]} id={id} onClick={addSection} />
					  );
					})
				}
			</>

			
			{Question.END === true && (
				<Choice text='This is the end of the our demo. Click here to start over!' onClick={() => resetDemo()} />)}
		</>
	);
}

export default DemoSection;

/*
{responseComplete && isLast && Doctor && (
	<>
	<div className="agent">
		<div className="text">I think {Doctor.fields.Name} could help you.</div>
	</div>
	<div className="item" onClick={handleClick}>
		{Doctor.fields['Doctor Pic Attachment'] && (<img src={Doctor.fields['Doctor Pic Attachment'][0].url} alt={Doctor.fields.Name} />)}
		<span><strong>{Doctor.fields.Name}</strong></span>
		<span>{Doctor.fields['Brief Bio']}</span>
	</div>
	</>
)}
*/